import { createSlice } from '@reduxjs/toolkit';
import { dispatch, getState } from '../store';
import generateWeekSchedule from '../../utils/generateWeekSchedule';
import {
  allDayMenuSchedule,
  DAY_NAMES,
  defaultSchedule,
} from '../../constants/constants';
import { formatApiError } from '../../utils';
import crave from '../../apis/crave';
import { openToast } from './snackbar';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  menus: [],
  formattedMenus: {
    tableHeadings: ['NAME', '', 'NO. OF CATEGORIES', 'MENU HOURS', ''],
    tableRows: [],
  },
  menuDetails: {
    id: '',
    name: '',
    description: '',
    categories: [],
    allDayMenu: false,
    schedule: defaultSchedule,
  },
  isCreateMenuDialogOpen: false,
  isDeleteMenuModalOpen: false,
  isEditMenuModalOpen: false,
};

const slice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    setEditMenuModalData(state, action) {
      state.isEditMenuModalOpen = action.payload.isEditMenuModalOpen;
      state.menuDetails = {
        id: action.payload.id || '',
        name: action.payload.name || '',
        categories: action.payload.categories || [],
        allDayMenu: action.payload.allDayMenu || false,
        schedule: action.payload.schedule || defaultSchedule,
      };
    },

    setDeleteMenuModalData(state, action) {
      state.isDeleteMenuModalOpen = action.payload.isDeleteMenuModalOpen;
      state.menuDetails = {
        id: action.payload.id || '',
      };
    },

    // OPEN MENU MODAL
    openMenuModal(state, action) {
      state[action.payload] = true;
    },

    // CLOSE MENU MODAL
    closeMenuModal(state, action) {
      state[action.payload] = false;
    },

    getMenusSuccess(state, action) {
      state.loading = false;
      state.menus = action.payload.menus;
      state.formattedMenus.tableRows = action.payload.formattedMenus;
    },

    createMenuSuccess(state, action) {
      state.loading = false;
      state.menus = action.payload.menus;
      state.formattedMenus.tableRows = action.payload.formattedMenus;
      state.isCreateMenuDialogOpen = false;
      state.menuDetails = initialState.menuDetails;
    },

    deleteMenuSuccess(state, action) {
      state.loading = false;
      state.menus = action.payload.menus;
      state.formattedMenus.tableRows = action.payload.formattedMenus;
      state.isDeleteMenuModalOpen = false;
    },

    editMenuSuccess(state, action) {
      state.loading = false;
      state.menus = action.payload.menus;
      state.formattedMenus.tableRows = action.payload.formattedMenus;
      state.isEditMenuModalOpen = false;
      state.menuDetails = {
        id: '',
        name: '',
        categories: [],
        allDayMenu: false,
        schedule: defaultSchedule,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openMenuModal,
  closeMenuModal,
  setEditMenuModalData,
  setDeleteMenuModalData,
} = slice.actions;

// ----------------------------------------------------------------------
const handleFormatMenusData = (menus = []) => {
  const formattedMenus = menus.map((menu) => {
    const weeklySchedule = generateWeekSchedule(
      menu.allDayMenu ? allDayMenuSchedule : menu.schedule
    );
    let shortText = '';
    const tooltipData = weeklySchedule.map((scheduleSingle) => {
      const { days, intervals } = scheduleSingle;
      const day =
        days.length > 1
          ? `${DAY_NAMES[days[0]].fullName} - ${
              DAY_NAMES[days[days.length - 1]].fullName
            }`
          : DAY_NAMES[days[0]].fullName;
      if (shortText.length < 15) {
        shortText = `${shortText} ${day}`;
      }
      const intervalsNew = intervals.map(
        (interval) => `${interval.from}-${interval.to}`
      );

      return { text: day, details: intervalsNew };
    });

    return {
      item: menu,
      tableRow: [
        { text: menu.name },
        { text: '' },
        { text: menu.categories?.length },
        { text: `${shortText}...`, tooltipData },
        { text: '' },
      ],
    };
  });
  const updatedMenus = menus.map((menuSingle) =>
    menuSingle.allDayMenu
      ? {
          ...menuSingle,
          schedule: allDayMenuSchedule,
        }
      : menuSingle
  );
  return { menus: updatedMenus, formattedMenus };
};

// ----------------------------------------------------------------------

export function getMenus() {
  return async () => {
    try {
      const { auth } = getState();
      const { merchantId, locationId, token } = auth.user;
      dispatch(slice.actions.startLoading());

      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.get(
        `merchants/${merchantId}/locations/${locationId}/menus`,
        params
      );

      const { formattedMenus, menus } = handleFormatMenusData(data.items);

      dispatch(slice.actions.getMenusSuccess({ formattedMenus, menus }));
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
    }
  };
}

// ----------------------------------------------------------------------

export function createMenu(newMenu) {
  return async () => {
    const { auth, menus: menusState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { menus } = menusState;
    dispatch(slice.actions.startLoading());
    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.post(
        `merchants/${merchantId}/locations/${locationId}/menus`,
        newMenu,
        params
      );
      const { formattedMenus, menus: newMenus } = handleFormatMenusData([
        ...menus,
        data,
      ]);
      dispatch(
        slice.actions.createMenuSuccess({ formattedMenus, menus: newMenus })
      );
      dispatch(
        openToast({ message: 'Menu successfully created', type: 'success' })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMenu() {
  return async () => {
    const { auth, menus: menusState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { menuDetails, menus } = menusState;
    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      await crave.delete(
        `merchants/${merchantId}/locations/${locationId}/menus/${menuDetails.id}`,
        params
      );
      const { formattedMenus, menus: newMenus } = handleFormatMenusData(
        menus.filter((menu) => menu.id !== menuDetails.id)
      );
      dispatch(
        slice.actions.deleteMenuSuccess({ formattedMenus, menus: newMenus })
      );
      dispatch(
        openToast({ message: 'Menu deleted successfully', type: 'info' })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}

// ----------------------------------------------------------------------

export function editMenu(updateMenu) {
  return async () => {
    const { auth, menus: menusState } = getState();
    const { merchantId, locationId, token } = auth.user;
    const { menus } = menusState;

    dispatch(slice.actions.startLoading());

    try {
      const params = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await crave.patch(
        `merchants/${merchantId}/locations/${locationId}/menus/${updateMenu.id}`,
        updateMenu,
        params
      );
      const { formattedMenus, menus: newMenus } = handleFormatMenusData(
        menus.map((menu) => (menu.id === data.id ? data : menu))
      );
      dispatch(
        slice.actions.editMenuSuccess({ formattedMenus, menus: newMenus })
      );
      dispatch(
        openToast({ message: 'Menu updated successfully', type: 'info' })
      );
    } catch (error) {
      const { e } = formatApiError(error);
      dispatch(slice.actions.hasError(e));
      dispatch(openToast({ message: e, type: 'error' }));
    }
  };
}
